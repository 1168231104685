<template class="container-fluid bg-light">
    <div class="LayoutDefault px-0 pt-0" style="width:100%">
        <!-- <header> -->
            <!-- <b-navbar class="pt-0 pb-0 spainhNavbar" toggleable="lg" :sticky="true" style="z-index: 3 !important; position: fixed; width: 100%;" v-bind:class="{'is-pinned':isFixed, 'is-not-pinned':!isFixed}"> -->
            <b-navbar class="pt-0 pb-0 spainhNavbar" toggleable="lg" :sticky="true" >
                <b-navbar-brand href="/" class="d-flex pb-4">
                    <div class="pt-0 d-flex" style="margin-left:6rem">
                        <img src="../assets/LOGO REPAHIS.png" class="img-fluid my-auto" style="height:80px;width:200px" />
                    </div>
                </b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav align="center" class="pe-5 ms-auto mt-auto mb-lg-0 mb-md-0 false" :small="true">
                        <b-nav-item class="spainhn-main-menu-text text-monospace mt-auto border-start h-100" href="/">
                            <p class="mt-auto pt-3" style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_INICIO')}}</p>
                        </b-nav-item>
                        <b-nav-item class="spainhn-main-menu-text text-monospace mt-auto border-start" href="/castillos">
                            <p style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_MONUMENTOS')}}</p>
                        </b-nav-item>
                        <b-nav-item class="spainhn-main-menu-text text-monospace mt-auto border-start h-100" href="/rutas-recomendadas">
                            <p style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_RUTAS')}}</p>
                        </b-nav-item>
                        <b-nav-item class="spainhn-main-menu-text mt-auto border-start" href="/blog">
                            <p style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_NOTICIAS') }}</p>
                        </b-nav-item>
                        <!-- <b-nav-item class="spainhn-main-menu-text mt-auto border-start">
                            <p style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_SERVICIOS')}}</p>
                            <b-dropdown-item>

                            </b-dropdown-item>
                        </b-nav-item> -->
                        <b-nav-item-dropdown id="servicios-dropdown" right dropright class="spainhn-main-menu-text my-auto border-start"  :text="$t('default.MAIN_MENU_SERVICIOS')">
                            <b-dropdown-item class="spainhn-main-menu-text" href="/castillos/servicios/visitas">{{ $t('default.MAIN_MENU_SERVICES_VISITS') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/servicios/alojamiento">{{ $t('default.MAIN_MENU_SERVICES_ALOJAMIENTO') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/servicios/restaurantes">{{ $t('default.MAIN_MENU_SERVICES_RESTAURANTES') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/castillos/servicios/eventos-singulares">{{ $t('default.MAIN_MENU_SERVICES_EVENTOS') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/castillos/servicios/experiencias-exclusivas">{{ $t('default.MAIN_MENU_SERVICES_EXPERIENCIAS') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/servicios/bodas">{{ $t('default.MAIN_MENU_SERVICES_BODA') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/servicios/espacios">{{ $t('default.MAIN_MENU_SERVICES_ESPACIOS') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/servicios/exposiciones">{{ $t('default.MAIN_MENU_SERVICES_MUSEOS') }}</b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item class="spainhn-main-menu-text mt-auto border-start border-end me-2 d-flex w-100" href="/clientes/login">
                            <div class="d-flex">
                                <font-awesome-icon class="mt-1 me-1" icon="fa-circle-user"></font-awesome-icon>
                                <p class="" style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_ACCEDER')}}</p>
                            </div>
                        </b-nav-item>
                        <div class="d-flex  my-auto h-100">
                            <div class="d-flex my-auto">
                            <font-awesome-icon class="my-auto" icon="fa-cart-shopping" v-bind:class="{'fa-bounce':nuevoItemAgregado}"></font-awesome-icon>
                            </div>
                        </div>
                        <b-nav-item-dropdown right class="spainhn-main-menu-text my-auto " no-caret :text="ticketsAmount" href="/carrito">
                            <b-dropdown-item v-for="(item) in itemsInCart" v-bind:key="item.uuid">
                                <!-- {{item.itemUuid}} -->
                                <entrada-resumen class="me-auto" :item="item"/>
                                <!-- Hola -->
                            </b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item>
                                <strong>Importe Total {{(totalAPagar).toLocaleString()}} €</strong>
                            </b-dropdown-item>
                            <b-dropdown-item class="mx-auto" href="/carrito">
                                <strong class="mx-auto">Ver carrito</strong>
                            </b-dropdown-item>
                                <!-- <p style="color:rgb(77,77,77) !important;"><span v-bind:class="{'d-none':!ticketsAmount}">{{ticketsAmount}} </span><span v-bind:class="{'d-none':ticketsAmount}">{{ $t('default.MAIN_MENU_CARRITO')}}</span> <span v-bind:class="{'d-none':!ticketsAmount}">Visita(s)</span> </p> -->
                        </b-nav-item-dropdown>
                        <div role="button" @click="changeLanguage" class="d-flex">
                            <country-flag class="my-auto" style="border-radius:40% 40% 50% 50%;" :country="idioma"  size='normal'/>
                        </div>
                    </b-navbar-nav>
                    
                </b-collapse>
                
            </b-navbar>
            

            
        <!-- </header> -->
        <main class="LayoutDefault__main bg-light container-fluid px-0">
            <!-- <div class="me-auto" style="z-index:2; position:sticky; top:20px;">
                <font-awesome-icon icon="fa-cart-shopping"></font-awesome-icon>
            </div> -->

            <slot/>
        </main>
        <div class="d-flex w-100 newsletter" style="min-height:30rem;" v-bind:style="{backgroundImage:newsletterImage}">
            <div class="d-flex mx-auto my-auto" style="min-height:18em; width:28vw; min-width:300px;">
                <div class="mx-auto bg-white px-3 pt-3 pb-3" style="border-radius:10px;">
                    <b-form class="pb-3">
                        <h5 class="mx-auto spainhn-subtitle-text"><small>Suscríbete a nuestro newsletter</small></h5>
                        <p class="spainhn-small-text"><strong>Recibe novedades y descuentos de Spain Heritage Network y comienza tu aventura</strong></p>
                        <b-form-group class="my-0">
                            <p class="spainhn-text my-0"><strong>INTRODUCE TU EMAIL</strong></p>
                            <b-form-input type="email" size="sm" style="border-radius:10px;" class="w-50 mx-auto" placeholder="hola@ejemplo.com"></b-form-input>
                        </b-form-group>
                        <b-form-checkbox class="text-start d-flex my-3">
                            <small>Acepto los <a class="link-dark" href="/condiciones-generales">términos y condiciones de privacidad</a> de Spain  Heritage Network</small>
                        </b-form-checkbox>
                        <mdb-btn color="indigo" class="spainhn-button">¡Suscribirme!</mdb-btn>
                        <a class="spainhn-small-text mt-3 link-dark d-block" href="/privacidad-cookies"><small><strong>Acerca del tratamiento de datos de Spain Heritage Network</strong></small></a>
                    </b-form>
                </div>
            </div>
        </div>
        <footer class="LayoutDefault_footer bg-dark text-white">
            <!-- <cookie-law theme="dark-lime">
                <div slot="message">
                </div>
                <div slot-scope="props">
                    <div class="d-flex w-100">
                        <p class="ms-auto me-4 my-auto">
                            Este sitio web utiliza cookies propias para mejorar su experiencia de navegación. <router-link to="privacidad-cookies">Políticas de privacidad</router-link>
                        </p>
                        <div class="d-flex ms-auto">
                            <button class="skew ms-auto" @click="props.accept"><span>Acepto</span></button>            
                            <button class="skew" @click="props.close"><span>Ignorar</span></button>
                        </div>

                    </div>
                </div>
            </cookie-law> -->
            
            <div class="row w-100 pt-4 pb-4">
                <b-col cols="12" md="4" class="ps-4 mb-4 mb-md-0">
                    <img src="@/assets/LOGO REPAHIS (BLANCO SIN FONDO).png" class="img-fluid w-50"/>
                </b-col>
                <b-col cols="5" md="2">
                    
                        <div class="d-flex align-items-start">
                            <small class="fw-bold" style="font-size:small">Spain Heritage Network</small>
                        </div>
                        <div class="d-flex flex-column mt-3 text-start spainhn-text">
                            <a href="/quienes-somos" class="link-light"><small>{{$t('default.FOOTER_WHO')}}</small></a>
                            <a href="/aviso-legal" class="link-light"><small>{{$t('default.FOOTER_LEGAL')}}</small></a>
                            <a href="/privacidad-cookies" class="link-light"><small>{{$t('default.FOOTER_PRIVACY_AND_COOKIES')}}</small></a>
                            <a href="/condiciones-generales" class="link-light"><small>{{$t('default.FOOTER_SELL_GENERALS')}}</small></a>
                            <a href="/contacto" class="link-light"><small>{{$t('default.FOOTER_CONTACT')}}</small></a>
                            <a href="https://admin.spainheritagenetwork.com" class="link-light"><small>  {{$t('default.FOOTER_ACCESS')}}  </small></a>
                        </div>
                    
                </b-col>
                <b-col cols="5" md="3" class="ps-0 ps-md-4">
                    <div class="d-flex align-items-start text-start">
                        <small class="fw-bold" style="font-size:small">{{ $t("default.FOOTER_CONTACT_TITLE") }}</small>
                    </div>
                    <div class="d-flex flex-column align-items-start text-start mt-3 text-start spainhn-text">
                        <small>Email: info@spainheritagenetwork.com</small>
                        <div class="d-flex mt-3 text-start">
                            <a href="https://www.facebook.com/castillosypalaciosdeespana" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-facebook-square" size="2x"></font-awesome-icon></a>
                            <a href="https://twitter.com/redcastillos" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-twitter-square" size="2x"></font-awesome-icon></a>
                            <a href="https://www.instagram.com/redcastillosypalacios/" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-instagram-square" size="2x"></font-awesome-icon></a>
                            <a href="https://www.linkedin.com/company/asociaci%C3%B3n-y-club-de-producto-castillos-y-palacios-tur%C3%ADsticos-de-espa%C3%B1a/" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-linkedin" size="2x"></font-awesome-icon></a>
                        </div>
                    </div>
                </b-col>
            </div>
        </footer>
    </div>
</template>

<script>
const defaultLayout = 'AppLayoutDefault';
import EntradaResumen from '../components/EntradaResumen.vue'
import CountryFlag from 'vue-country-flag'
import ApiService from '../services/ApiService';
// import CookieLaw from 'vue-cookie-law';
// import IconBase from '../components/IconBase.vue';
// import IconBaseStrech from '../components/IconBaseStrech.vue'
// import IconBase from '../components/IconBase.vue';

const el = document.querySelector(".spainhNavbar")
const observer = new IntersectionObserver( 
  ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
  { threshold: [1] }
);

// observer.observe(el);

export default {
    name:"SpainHeritageAdminLayout",
    components:{
        CountryFlag,
        EntradaResumen,
        // CookieLaw
        // IconBase,
        // IconBaseStrech
        // IconBase
    },
    data(){
        return{
            newsletterImage: `url(${require('@/assets/newsletter-background.jpg')})`,
            idioma: 'es',
            scrolling: false
        }
    },
    methods:{
        changeLanguage(){
            let $vm = this;
            if(this.$i18n.locale == 'es'){
                this.$i18n.locale="en";
                $vm.idioma = 'gb';
            }
            else if(this.$i18n.locale == 'en'){
                console.log("Cambiando a francés idioma");
                this.$i18n.locale = "fr";
                $vm.idioma = 'fr';
            }
            else if(this.$i18n.locale == 'fr'){
                this.$i18n.locale = "de";
                $vm.idioma = 'de';
            }
            else if(this.$i18n.locale == 'de'){
                this.$i18n.locale = "es";
                $vm.idioma = 'es';
            }
            
            $vm.$store.commit('setIdioma', this.$i18n.locale);
        },
        handleScroll(){
            if(window.pageYOffset > 10.0)
                this.scrolling = true;
            else
                this.scrolling = false;
        }
    },
    computed: {
        layout(){
            const layout = this.$route.meta.layout || defaultLayout;
            return () => import(`@/layouts/${layout}.vue`)
        },
        ticketsAmount(){

            let amount = this.$store.getters.numberOfTickets;

            if(amount == 0){
                return this.$t('default.MAIN_MENU_CARRITO');
            }
            else{
                return amount + " Visita(s)";
            }
        },
        itemsInCart(){
            return this.$store.getters.itemsInCart;
        },
        nuevoItemAgregado(){
            return this.$store.getters.cartAnimation;
        },
        totalAPagar(){
            let totalAPagar = this.$store.getters.total;

            if(totalAPagar != null){
                return totalAPagar.toLocaleString('es-ES', { maximumFractionDigits:2, minimumFracionDigits:2 } );
            }
            else{
                return "ups"
            }
            // return this.$store.getters.total;
        },
        isFixed(){
            // if(this.scrolling ) {
            //     return true;
            // }
            // else{
            //     return false;
            // }
            return false;
        }
    },
    async created(){
        let $vm = this;
        $vm.$i18n.locale = $vm.$store.getters.getIdioma ?? 'es';

        window.addEventListener('scroll', this.handleScroll);

        if($vm.$i18n.locale == null){
            $vm.$i18n.locale = 'es';
        }
        if(this.$i18n.locale == 'en'){
            // this.$i18n.locale="es";
            $vm.idioma = 'gb';
        }
        else if(this.$i18n.locale == 'es'){
            // this.$i18n.locale = "en";
            $vm.idioma = 'es';
        }
        else if(this.$i18n.locale == 'fr'){
            $vm.idioma = 'fr';
        }
        else if(this.$i18n.locale == 'de'){
            $vm.idioma = 'de';
        }

        const cartUuid = this.$store.getters.getCartUuid;
        await ApiService.getCarrito( cartUuid );
    },
    mounted(){
        // console.log(el);
        // observer.observe(el);
    },
    destroyed(){
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style scoped>
    .newsletter{
        /* background-image: `url(${require('@/assets/newsletter-background.jpg')})`; */
        /* background-image: url('../assets/newsletter-background.jpg'); */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    /* styles for when the header is in sticky mode */
    .is-pinned {
        background-color: rgba(255,255,255,1) !important;
    } 

    .is-not-pinned{
        background-color: rgba(255,255,255,0.6) !important;
    }
</style>
