var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.validarRegistro.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "row spainhn-solid-red-background shadow py-2 mb-4" },
          [
            _c(
              "mdb-col",
              {
                staticClass: "my-auto d-none d-md-flex",
                attrs: { sm: "auto" },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "my-autop ps-3",
                  attrs: { icon: "fa-user", size: "lg" },
                }),
              ],
              1
            ),
            _c(
              "mdb-col",
              {
                staticClass: "fw-bold spainhn-subtitle-2-white text-center",
                staticStyle: { "font-size": "22px" },
                attrs: { col: "sm" },
              },
              [_vm._v(" " + _vm._s(_vm.$t("default.CART_CONFIRM_TEXT")) + " ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row mt-2" },
          [
            _c(
              "mdb-col",
              { attrs: { col: "12", md: "4" } },
              [
                _c("mdb-input", {
                  ref: "clienteNombre",
                  staticClass: "mt-4 shadow",
                  attrs: {
                    required: "",
                    invalidFeedback: "Introduzca un nombre",
                    customValidation: _vm.validation.clienteNombre.validated,
                    isValid: _vm.validation.clienteNombre.valid,
                    label: "*" + _vm.$t("default.CART_FORM_NAME_PLACEHOLDER"),
                    outline: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.validate("clienteNombre")
                    },
                  },
                  model: {
                    value: _vm.dataForm.clienteNombre,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "clienteNombre", $$v)
                    },
                    expression: "dataForm.clienteNombre",
                  },
                }),
              ],
              1
            ),
            _c(
              "mdb-col",
              { attrs: { col: "12", md: "4" } },
              [
                _c("mdb-input", {
                  ref: "telefono",
                  staticClass: "mt-4 shadow",
                  attrs: {
                    required: "",
                    type: "tel",
                    invalidFeedback:
                      "Su número debe incluir al menos 9 dígitos",
                    customValidation: _vm.validation.telefono.validated,
                    isValid: _vm.validation.telefono.valid,
                    label: "*" + _vm.$t("default.CART_FORM_PHONE_PLACEHOLDER"),
                    outline: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.validate("telefono")
                    },
                  },
                  model: {
                    value: _vm.dataForm.telefonoCliente,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "telefonoCliente", $$v)
                    },
                    expression: "dataForm.telefonoCliente",
                  },
                }),
              ],
              1
            ),
            _c(
              "mdb-col",
              { attrs: { col: "12", md: "4" } },
              [
                _c("mdb-input", {
                  ref: "idCliente",
                  staticClass: "shadow",
                  attrs: {
                    invalidFeedback: "Su DNI/Pasaporte no es un número válido",
                    customValidation: _vm.validation.dni.validated,
                    isValid: _vm.validation.dni.valid,
                    maxlength: "9",
                    label:
                      "*" + _vm.$t("default.CART_FORM_PASSPORT_PLACEHOLDER"),
                    required: "",
                    outline: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.validate("dni")
                    },
                  },
                  model: {
                    value: _vm.dataForm.idCliente,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "idCliente", $$v)
                    },
                    expression: "dataForm.idCliente",
                  },
                }),
              ],
              1
            ),
            _c("mdb-col", { attrs: { col: "0", md: "8" } }),
            _c(
              "mdb-col",
              {
                staticClass: "my-auto text-start",
                attrs: { col: "12", md: "4" },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("mdb-input", {
                      ref: "dni",
                      attrs: {
                        name: "tipo-id",
                        id: "tipo-id-dni",
                        radioValue: "DNI",
                        label: "DNI/NIF",
                        type: "radio",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.validate("dni")
                        },
                      },
                      model: {
                        value: _vm.dataForm.tipoId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "tipoId", $$v)
                        },
                        expression: "dataForm.tipoId",
                      },
                    }),
                    _c("mdb-input", {
                      attrs: {
                        type: "radio",
                        name: "tipo-id",
                        id: "tipo-id-passport",
                        radioValue: "Passport",
                        label: _vm.$t("default.CART_FORM_PASSPORT"),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.validate("dni")
                        },
                      },
                      model: {
                        value: _vm.dataForm.tipoId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "tipoId", $$v)
                        },
                        expression: "dataForm.tipoId",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "mdb-col",
              { attrs: { col: "12", md: "4" } },
              [
                _c("mdb-input", {
                  ref: "email",
                  staticClass: "shadow",
                  attrs: {
                    required: "",
                    type: "email",
                    invalidFeedback: "Campo obligatorio*",
                    customValidation: _vm.validation.email.validated,
                    isValid: _vm.validation.email.valid,
                    label: "*" + _vm.$t("default.CART_FORM_EMAIL_PLACEHOLDER"),
                    outline: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.validate("email")
                    },
                  },
                  model: {
                    value: _vm.dataForm.emailCliente,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "emailCliente", $$v)
                    },
                    expression: "dataForm.emailCliente",
                  },
                }),
              ],
              1
            ),
            _c(
              "mdb-col",
              { attrs: { col: "12", md: "4" } },
              [
                _c("mdb-input", {
                  ref: "emailConfirm",
                  staticClass: "shadow",
                  attrs: {
                    required: "",
                    outline: "",
                    customValidation: _vm.validation.email.validated,
                    isValid: _vm.validation.email.valid,
                    invalidFeedback: "Los correos no coinciden",
                    type: "email",
                    label:
                      "*" +
                      _vm.$t("default.CART_FORM_EMAIL_CONFIRM_PLACEHOLDER"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.validate("email")
                    },
                  },
                  model: {
                    value: _vm.dataForm.emailConfirmation,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "emailConfirmation", $$v)
                    },
                    expression: "dataForm.emailConfirmation",
                  },
                }),
              ],
              1
            ),
            _c(
              "mdb-col",
              { attrs: { col: "12", md: "4" } },
              [
                _c("mdb-select", {
                  staticClass: "mt-2 shadow",
                  attrs: {
                    label: _vm.$t("default.FORM.COUNTRY"),
                    color: "danger",
                    validation: "",
                    invalidFeedback: "Campo obligatorio *",
                    placeholder: "Elige tu país",
                    outline: "",
                    search: "",
                    required: "",
                  },
                  on: { change: _vm.setToCountry },
                  model: {
                    value: _vm.paises,
                    callback: function ($$v) {
                      _vm.paises = $$v
                    },
                    expression: "paises",
                  },
                }),
              ],
              1
            ),
            _c(
              "mdb-col",
              { attrs: { col: "12", md: "4" } },
              [
                _c("mdb-input", {
                  ref: "zipCode",
                  staticClass: "shadow",
                  class: { "d-none": _vm.dataForm.pais !== "ES" },
                  attrs: {
                    customValidation: _vm.validation.zipCode.validated,
                    isValid: _vm.validation.zipCode.valid,
                    label:
                      "*" + _vm.$t("default.CART_FORM_ZIPCODE_PLACEHOLDER"),
                    required: _vm.dataForm.pais === "ES" ? true : false,
                    invalidFeedback: "Código postal inválido",
                    maxlength: "5",
                    outline: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.validate("zipCode")
                    },
                  },
                  model: {
                    value: _vm.dataForm.zipCodeCliente,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "zipCodeCliente", $$v)
                    },
                    expression: "dataForm.zipCodeCliente",
                  },
                }),
              ],
              1
            ),
            _c(
              "mdb-col",
              { attrs: { col: "12", md: "4" } },
              [
                _c("mdb-input", {
                  staticClass: "shadow",
                  class: { "d-none": _vm.dataForm.pais !== "ES" },
                  attrs: {
                    outline: "",
                    type: "text",
                    disabled: "",
                    placeholder: "Comunidad autónoma",
                    label: "Comunidad Autónoma",
                  },
                  model: {
                    value: _vm.dataForm.comunity,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "comunity", $$v)
                    },
                    expression: "dataForm.comunity",
                  },
                }),
              ],
              1
            ),
            _c(
              "mdb-col",
              { attrs: { col: "12", md: "4" } },
              [
                _c("mdb-input", {
                  staticClass: "shadow",
                  attrs: {
                    outline: "",
                    type: "text",
                    disabled: "",
                    placeholder: "Provincia",
                    label: "Provincia",
                  },
                  model: {
                    value: _vm.dataForm.provinciaCliente,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "provinciaCliente", $$v)
                    },
                    expression: "dataForm.provinciaCliente",
                  },
                }),
              ],
              1
            ),
            _vm.dataForm.emailCliente
              ? _c(
                  "mdb-col",
                  { attrs: { col: "12" } },
                  [
                    _c(
                      "mdb-row",
                      [
                        _c(
                          "mdb-col",
                          { attrs: { col: "12", md: "4" } },
                          [
                            _c("mdb-input", {
                              staticClass: "shadow",
                              attrs: {
                                outline: "",
                                type: "text",
                                placeholder: "",
                                label: _vm.$t("default.FORM.COUPON"),
                                disabled: _vm.readOnlyCoupon,
                              },
                              model: {
                                value: _vm.couponCode,
                                callback: function ($$v) {
                                  _vm.couponCode = $$v
                                },
                                expression: "couponCode",
                              },
                            }),
                          ],
                          1
                        ),
                        !_vm.codigoYaAplicado
                          ? _c(
                              "mdb-col",
                              {
                                staticClass: "text-left my-auto",
                                attrs: { col: "12", md: "4" },
                              },
                              [
                                _c(
                                  "mdb-btn",
                                  {
                                    attrs: {
                                      color: "danger",
                                      disabled:
                                        !_vm.validation.email.valid ||
                                        _vm.dataForm.emailCliente === "" ||
                                        _vm.applyingCupon,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.validateCoupon()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("default.FORM.APPLY_COUPON")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.codigoYaAplicado
                          ? _c(
                              "mdb-col",
                              {
                                staticClass: "text-left my-auto",
                                attrs: { col: "12", md: "4" },
                              },
                              [
                                _c(
                                  "mdb-btn",
                                  {
                                    attrs: { color: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.quitarCupon()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("default.FORM.REMOVE_COUPON")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "mdb-col",
                          {
                            staticClass: "text-start",
                            attrs: { col: "12", md: "4" },
                          },
                          [
                            _c("label", { staticClass: "fw-bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("default.FORM.DISCOUNT_APPLIED")
                                ) + ":"
                              ),
                            ]),
                            _c("p", { staticClass: "fw-bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.descuentoAplicable.toLocaleString(
                                    "es-ES",
                                    { style: "currency", currency: "EUR" }
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "mdb-col",
              { attrs: { col: "12 text-start" } },
              [
                _c("mdb-input", {
                  attrs: {
                    type: "checkbox",
                    size: "lg",
                    customValidation: _vm.validation.acepto.validated,
                    name: "acepto",
                    id: "acepto",
                    isValid: _vm.validation.acepto.valid,
                    required: "",
                    label: _vm.$t("default.CART_FORM_GENERAL_TERMS"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.validate("acepto")
                    },
                  },
                  model: {
                    value: _vm.aceptoTerminos,
                    callback: function ($$v) {
                      _vm.aceptoTerminos = $$v
                    },
                    expression: "aceptoTerminos",
                  },
                }),
                _c("mdb-input", {
                  staticClass: "mt-2",
                  attrs: {
                    size: "lg",
                    name: "newsletter",
                    id: "newsletter",
                    type: "checkbox",
                    label: _vm.$t("default.CART_FORM_NEWSLETTER"),
                  },
                  model: {
                    value: _vm.dataForm.aceptoNewsletter,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "aceptoNewsletter", $$v)
                    },
                    expression: "dataForm.aceptoNewsletter",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-3 text-start" },
          [
            _c(
              "mdb-btn",
              {
                staticClass: "mt-4 mb-4 ms-auto spainhn-button",
                attrs: {
                  color: "indigo",
                  type: "submit",
                  disabled: _vm.loading,
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("default.CART_CONFIRM_PURCHASE")) + " "
                ),
              ]
            ),
            _c("p", { staticClass: "spainhn-text pb-0 mb-0" }, [
              _c("small", { staticClass: "fw-bold" }, [
                _vm._v(_vm._s(_vm.$t("default.TITULO_TRATAMIENTO_DATOS"))),
              ]),
            ]),
            _c("p", { staticClass: "mt-0 pt-0" }, [
              _c("small", [
                _vm._v(_vm._s(_vm.$t("default.MENSAJE_RESPONSABLE_DE_DATOS"))),
              ]),
            ]),
            _c("ul", [
              _c("li", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.$t("default.MENSAJE_EXPLICACION_DE_DATOS_1"))
                  ),
                ]),
              ]),
              _c("li", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.$t("default.MENSAJE_EXPLICACION_DE_DATOS_2"))
                  ),
                ]),
              ]),
              _c("li", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.$t("default.MENSAJE_EXPLICACION_DE_DATOS_3"))
                  ),
                ]),
              ]),
              _c("li", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.$t("default.MENSAJE_EXPLICACION_DE_DATOS_4"))
                  ),
                ]),
              ]),
              _c("li", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.$t("default.MENSAJE_EXPLICACION_DE_DATOS_5"))
                  ),
                ]),
              ]),
            ]),
            _c("p", [
              _c("small", [
                _vm._v(
                  _vm._s(_vm.$t("default.MENSAJE_EXPLICACION_DE_DATOS_6"))
                ),
              ]),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }