<template>
    <div class="container ">
        <form novalidate @submit.prevent="validarRegistro">
            
            <div class="row spainhn-solid-red-background shadow py-2 mb-4">
                <mdb-col class="my-auto d-none d-md-flex" sm="auto">
                    <font-awesome-icon icon="fa-user" class="my-autop ps-3" size="lg"></font-awesome-icon>
                </mdb-col>
                <mdb-col class="fw-bold spainhn-subtitle-2-white text-center" col="sm" style="font-size:22px;">
                    {{$t('default.CART_CONFIRM_TEXT')}}
                </mdb-col>
            </div>

            <div class="row mt-2">
                <mdb-col col="12" md="4">
                    <mdb-input ref="clienteNombre" required v-model="dataForm.clienteNombre" 
                    class="mt-4 shadow" invalidFeedback="Introduzca un nombre"
                    :customValidation="validation.clienteNombre.validated" :isValid="validation.clienteNombre.valid" 
                    @change="validate('clienteNombre')" :label="'*' + $t('default.CART_FORM_NAME_PLACEHOLDER')" outline/>
                </mdb-col>

                <mdb-col col="12" md="4">
                    <mdb-input required v-model="dataForm.telefonoCliente" ref="telefono" type="tel" class="mt-4 shadow"
                    invalidFeedback="Su número debe incluir al menos 9 dígitos"
                    :customValidation="validation.telefono.validated" :isValid="validation.telefono.valid" @change="validate('telefono')"
                    :label="'*' + $t('default.CART_FORM_PHONE_PLACEHOLDER')" outline/>
                </mdb-col>

                <mdb-col col="12" md="4">
                    <mdb-input ref="idCliente" v-model="dataForm.idCliente" class="shadow" invalidFeedback="Su DNI/Pasaporte no es un número válido"
                    :customValidation="validation.dni.validated" :isValid="validation.dni.valid" @change="validate('dni')" maxlength="9"
                    :label="'*' + $t('default.CART_FORM_PASSPORT_PLACEHOLDER')" required outline/>
                </mdb-col>

                <mdb-col col="0" md="8">

                </mdb-col>
                <mdb-col class="my-auto text-start" col="12" md="4">
                    <div class="d-flex">
                        <mdb-input ref="dni" name="tipo-id" id="tipo-id-dni"  
                        radioValue="DNI" v-model="dataForm.tipoId" @change="validate('dni')"
                        class="" :label="'DNI/NIF'" type="radio"/>

                        <mdb-input type="radio" name="tipo-id" id="tipo-id-passport" 
                        class="" v-model="dataForm.tipoId" @change="validate('dni')"
                        radioValue="Passport" :label="$t('default.CART_FORM_PASSPORT')" />

                    </div>

                </mdb-col>

                <mdb-col col="12" md="4">
                    <mdb-input v-model="dataForm.emailCliente" required ref="email" class="shadow" type="email" invalidFeedback="Campo obligatorio*"
                    :customValidation="validation.email.validated" :isValid="validation.email.valid" @change="validate('email')"
                    :label="'*' + $t('default.CART_FORM_EMAIL_PLACEHOLDER')" outline/>
                </mdb-col>

                <mdb-col col="12" md="4">
                    <mdb-input v-model="dataForm.emailConfirmation" required ref="emailConfirm" class="shadow"
                    outline @change="validate('email')" :customValidation="validation.email.validated"
                    :isValid="validation.email.valid" invalidFeedback="Los correos no coinciden"
                    type="email" :label="'*' + $t('default.CART_FORM_EMAIL_CONFIRM_PLACEHOLDER')" />
                </mdb-col>

                <mdb-col col="12" md="4">
                    <mdb-select class="mt-2 shadow" @change="setToCountry"  :label="$t('default.FORM.COUNTRY')"
                        v-model="paises" color="danger" validation invalidFeedback="Campo obligatorio *"
                        placeholder="Elige tu país" outline search required/>
                </mdb-col>

                <mdb-col col="12" md="4">
                    <mdb-input v-model="dataForm.zipCodeCliente" :customValidation="validation.zipCode.validated"
                    :isValid="validation.zipCode.valid" @change="validate('zipCode')"
                    ref="zipCode" class="shadow" :label="'*' + $t('default.CART_FORM_ZIPCODE_PLACEHOLDER')" 
                    :required=" dataForm.pais === 'ES' ? true: false" invalidFeedback="Código postal inválido" maxlength="5"
                    v-bind:class="{ 'd-none': dataForm.pais !== 'ES' }" outline/>

                </mdb-col>

                <mdb-col col="12" md="4">

                    <mdb-input v-model="dataForm.comunity" outline class="shadow" type="text" v-bind:class="{ 'd-none': dataForm.pais !== 'ES' }"
                    disabled :placeholder="'Comunidad autónoma'" label="Comunidad Autónoma"/>

                </mdb-col>

                <mdb-col col="12" md="4">
                    <mdb-input v-model="dataForm.provinciaCliente" outline class="shadow" type="text" disabled :placeholder="'Provincia'" label="Provincia"/>
                </mdb-col>

                <mdb-col col="12" v-if="dataForm.emailCliente">
                    <mdb-row>
                        <mdb-col col="12" md="4">
                            <mdb-input v-model="couponCode" outline class="shadow" type="text" :placeholder="''" :label="$t('default.FORM.COUPON')" :disabled="readOnlyCoupon"/>
                        </mdb-col>
                        <mdb-col col="12" md="4" class="text-left my-auto" v-if="!codigoYaAplicado">
                            <mdb-btn color="danger" :disabled="!validation.email.valid || dataForm.emailCliente === '' || applyingCupon" @click="validateCoupon()">{{ $t("default.FORM.APPLY_COUPON") }}</mdb-btn>
                        </mdb-col>

                        <mdb-col col="12" md="4" class="text-left my-auto" v-if="codigoYaAplicado">
                            <mdb-btn color="danger" @click="quitarCupon()">{{ $t("default.FORM.REMOVE_COUPON") }}</mdb-btn>
                        </mdb-col>

                        <mdb-col col="12" md="4" class="text-start">
                          <label class="fw-bold">{{ $t("default.FORM.DISCOUNT_APPLIED") }}:</label>
                          <p class="fw-bold">{{ descuentoAplicable.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' }) }}</p>
                        </mdb-col>
                    </mdb-row>

                </mdb-col>

              <mdb-col col="12 text-start">
                    <mdb-input type="checkbox" size="lg" :customValidation="validation.acepto.validated" name="acepto" id="acepto"
                        :isValid="validation.acepto.valid" @change="validate('acepto')"
                        required v-model="aceptoTerminos" :label="$t('default.CART_FORM_GENERAL_TERMS')" />

                    <mdb-input class="mt-2" size="lg"  v-model="dataForm.aceptoNewsletter" name="newsletter" id="newsletter" type="checkbox" :label="$t('default.CART_FORM_NEWSLETTER')"/>

                </mdb-col>

            </div>

                    

            <div class="mt-3 text-start">
                <!-- <input type="text" name="Ds_SignatureVersion" v-model="Ds_SignatureVersion" hidden />
                <input type="text" name="Ds_MerchantParameters" v-model="Ds_MerchantParameters" hidden />
                <input type="text" name="Ds_Signature" v-model="Ds_Signature" hidden /> -->
                <mdb-btn color="indigo" class="mt-4 mb-4 ms-auto spainhn-button" type="submit"
                     :disabled="loading"> {{
                            $t('default.CART_CONFIRM_PURCHASE')
                    }} </mdb-btn>

                <p class="spainhn-text pb-0 mb-0">
                    <small class="fw-bold">{{ $t("default.TITULO_TRATAMIENTO_DATOS") }}</small>
                </p>
                <p class="mt-0 pt-0"><small>{{ $t("default.MENSAJE_RESPONSABLE_DE_DATOS") }}</small></p>

                <ul>
                    <li><small>{{ $t("default.MENSAJE_EXPLICACION_DE_DATOS_1") }}</small></li>
                    <li><small>{{ $t("default.MENSAJE_EXPLICACION_DE_DATOS_2") }}</small></li>
                    <li><small>{{ $t("default.MENSAJE_EXPLICACION_DE_DATOS_3") }}</small></li>
                    <li><small>{{ $t("default.MENSAJE_EXPLICACION_DE_DATOS_4") }}</small></li>
                    <li><small>{{ $t("default.MENSAJE_EXPLICACION_DE_DATOS_5") }}</small></li>
                </ul>
                <p><small>{{ $t("default.MENSAJE_EXPLICACION_DE_DATOS_6") }}</small></p>

            </div>

        </form>

    </div>
</template>

<script>
/*eslint no-useless-escape: "off"*/
import data from 'iso3166-2-db/countryList/dispute/UN/es';
import ApiService from '../services/ApiService';

export default{
    name:"FormularioCompraHorizontal",
    components:{

    },
    data(){
        return{
            redsysUrl: process.env.VUE_APP_REDSYS_URL,
            Ds_SignatureVersion: null,
            Ds_MerchantParameters: null,
            Ds_Signature: null,
            Ds_Mount: '0',
            loading: false,
            paises:[],
            provincias:[],
            couponCode: null,
            dataCoupon: null,
            applyingCupon: false,
            dataForm:{
                comunity: null,
                provinciaCode: null,
                tipoId: "DNI",
                emailCliente: null,
                emailConfirmation: null,
                telefonoCliente: null,
                provinciaCliente: null,
                zipCodeCliente: null,
                aceptoNewsletter: false,
                pais: 'ES',
                clienteNombre: null,
                idCliente: null,
            },
            validation:{
                email:{
                    validated: false,
                    valid: false
                },
                zipCode:{
                    validated: false,
                    valid: false
                },
                dni:{
                    validated: false,
                    valid: false
                },
                telefono:{
                    validated: false,
                    valid: false
                },
                acepto:{
                    validated:false,
                    valid: false
                },
                clienteNombre:{
                    validated: false,
                    valid: false
                }
            },
            aceptoTerminos: false,
            pasteDetected: false,
        }
    },
    methods:{
        /**
         * Validate the entry we want to check
         * @param {String} key - variable key we want to validate
         * @param {String} value -value for the validation
         */
        async validate(key, value){
            if( key === 'email' ){
                if( (this.dataForm.emailConfirmation === this.dataForm.emailCliente) && this.dataForm.emailConfirmation ){
                    this.validation['email'].valid = this.validateEmail(this.dataForm.emailCliente);
                }
                else{
                    this.validation['email'].valid = false;
                }
                // this.validation['email'].validated = true;
            }

            // Validate the zip code input
            else if( key === 'zipCode'){
                let zipCode = this.dataForm.zipCodeCliente ?? "";

                if( zipCode.length == 5  && this.dataForm.pais === 'ES' ){
                    
                    let isValid = this.validarCodigoPostal( this.dataForm.zipCodeCliente )

                    if(isValid){
                        // Obtenemos los dos primeros dígitos, para saber la provincia y la comunidad autónoma
                        await this.setComunityAndProvince( this.dataForm.zipCodeCliente );
                        this.validation['zipCode'].valid = true;
                    }
                    else{
                        this.validation['zipCode'].valid = false;
                        this.clearCodigoPostal();
                    }
                }

                else if( this.dataForm.pais !== 'ES'){

                    this.validation['zipCode'].valid = true;
                    this.dataForm.provinciaCliente = "Fuera de España/Out of Spain";
                    this.dataForm.provinciaCode = "out";
                    this.dataForm.comunity = null;

                }
                else{

                    this.validation['zipCode'].valid = false;
                    this.clearCodigoPostal();
                }

            }

            else if( key === 'dni' ){
                if( this.dataForm.idCliente ){
                    if( this.dataForm.tipoId === "DNI" ){
                        this.validation['dni'].valid = this.validateDNI( this.dataForm.idCliente );
                    }
                    else{
                        if( this.dataForm.idCliente ){
                            this.validation['dni'].valid = true;
                        }
                        else{
                            this.validation['dni'].valid = false;
                        }
                    }
                }
                else{
                    this.validation['dni'].valid = false;
                }

                // this.validation['dni'].validated = true;
            }

            else if( key === 'telefono'){

                let telefono = this.dataForm.telefonoCliente ?? "";
                if( telefono.length > 8){
                    this.validation['telefono'].valid = true
                }
                else{
                    this.validation['telefono'].valid = false;
                }
                // this.validation['telefono'].validated = true;
            }

            else if( key === 'acepto' ){
                if( this.aceptoTerminos === true){
                    this.validation['acepto'].valid = true;
                }
                else{
                    this.validation['acepto'].valid = false;
                }
                // this.validation[key] = true;
            }

            else if( key === 'clienteNombre'){
                let cliente = this.dataForm.clienteNombre ?? "";
                if(cliente.length > 3){
                    this.validation['clienteNombre'].valid = true;
                }
                else{
                    this.validation[key].valid = false;
                }
            }
            this.validation[key].validated = true;

        },
        /**
         * Verifica que el DNI sea válido
         * @param {String} dni - DNI que se validará
         */
        validateDNI(dni) {
            var numero, letr, letra;
            var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

            dni = dni.toUpperCase();

            if (expresion_regular_dni.test(dni) === true) {
                numero = dni.substr(0, dni.length - 1);
                numero = numero.replace('X', 0);
                numero = numero.replace('Y', 1);
                numero = numero.replace('Z', 2);
                letr = dni.substr(dni.length - 1, 1);
                numero = numero % 23;
                letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
                letra = letra.substring(numero, numero + 1);
                if (letra != letr) {
                    //alert('Dni erroneo, la letra del NIF no se corresponde');
                    return false;
                } else {
                    //alert('Dni correcto');
                    return true;
                }
            } else {
                //alert('Dni erroneo, formato no válido');
                return false;
            }
        },

        validateEmail(email){
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(email.match(mailformat))
            {
                return true;
            }
            else
            {
                return false;
            }
        },

      async applyCoupon( monumentoId, eventId, discount, discountType, couponType, couponId ){
            let $vm = this;
            let data = {
                couponCode: $vm.couponCode,
                cartId: $vm.$store.getters.cartStatus?.uuid,
                email: $vm.dataForm.emailCliente
            }

            try{

                const payload = {
                    monumentoId,
                    eventId,
                    couponCode: this.couponCode, 
                    promotion: discount,
                    discountType: discountType,
                    couponType,
                    couponId
                }
                this.$store.commit( 'addCouponToCartItem', payload );
                const totalAPagar = Number(($vm.$store.getters.total + ( (( this.$store.getters.seguroDeCancelacion) ? $vm.$store.getters.totalCancelacion : 0) ?? 0 ) - ($vm.$store.getters.descuentoPorPromociones ?? 0)).toFixed( 2 )) ;

                const totalString = totalAPagar?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR'});
                // this.$toast.success( `Su nuevo total es de: ${ totalAPagar?.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' } )}` );
                this.$toast.success( this.$t("default.NOTIFICATIONS.PRICE_UPDATE", { msg: totalString } ) );
            }catch( err ){
                console.log( err );
                this.$toast.error( this.$t("default.FORM.COUPON_ERROR") );
            }
        },

        async validateCoupon(){
            let $vm = this;
            
            try{
                $vm.applyingCupon = true;
                let data = {
                    couponCode: $vm.couponCode,
                    cartId: $vm.$store.getters.cartStatus?.uuid,
                    email: $vm.dataForm.emailCliente
                }
                let result = await ApiService.validateCoupon(data);
    
                if(result.data && result.data.isValid){
                    $vm.dataCoupon = result.data.coupon;
                    $vm.applyCoupon( result.data.monumentoId, result.data.eventoId, this.dataCoupon.discount, this.dataCoupon.discountType, this.dataCoupon.tipo, this.dataCoupon._id );
                }
                else{
                    $vm.dataCoupon = null;
                    $vm.applyingCupon = false;
                    this.$toast.warning( `${(result.data?.message )?? this.$t("default.FORM.INVALID_COUPON") }` );
                    // alert("Cupón inválido")
                }
            }catch( err ){
                this.applyingCupon = false;
                this.$toast.error(this.$t("default.FORM.INVALID_COUPON"), {
                    timeout: 4000
                });
            }
        },

        /**
         * Se utiliza para determinar si el código postal es válido o no
         * @param {String} codigo - Código postal a ser validado
         */
        validarCodigoPostal(codigo){
            // Convertimos el string que recibimos a un número, para poder corroborar que el
            // input sea un número válido
            let codeToNumber = parseInt( codigo );

            // Si el resultado nos indica que es falso, entonces se introdujeron letras, no solo números
            let isNumber = !isNaN(codeToNumber);

            let isValid = false;

            if( isNumber ){
                if(codeToNumber >= 1000 ){
                    isValid = true;
                }
            }
            return isValid;
        },
        /**
         * Limpiamos las variables de provincia y comunidad
         */
        clearCodigoPostal(){
            this.dataForm.provinciaCliente = null;
            this.dataForm.provinciaCode = null;
            this.dataForm.comunity = null;
        },

        /**
         * Se utiliza el código postal para determinar la provincia y la comunidad
         * @param {String} codigoPostal - Código postal que introdujo el usuario
         */
        async setComunityAndProvince(codigoPostal){
            let comunityCode = codigoPostal.substr(0, 2);
            // console.log(comunidad.data)

            this.provincias.forEach( provincia => {
                if(provincia.value === comunityCode){
                    provincia.selected = true;
                    this.dataForm.provinciaCliente = provincia.text;
                    this.dataForm.provinciaCode = provincia.value;
                    this.dataForm.comunity = provincia.comunidad;
                }
                else{
                    provincia.selected = false;
                }
            } )

        },
        // Muestra el resultado de la validación del formulario
        async validarRegistro(evt){
            evt.target.classList.add('was-validated')
            const validationKeys = Object.keys( this.validation )

            // Validamos todas las llaves
            validationKeys.forEach( key => this.validate(key) )

            // Revisamos si alguno es inválido
            let resultados = validationKeys.map( key => this.validation[key].valid ).filter( valid => !valid )

            // Esto quiere decir que no hay errores, por lo que se puede enviar la solicitud a redsys
            if(resultados.length == 0){
                this.dataOk();
            }

        },
        enviarRegistro(evt){
            evt.preventDefault();
        },
        setToCountry(evt) {
            let $vm = this;
            $vm.dataForm.pais = evt;

            if( evt !== 'ES'){
                this.dataForm.provinciaCliente = "Fuera de España/Out of Spain";
                this.dataForm.provinciaCode = "out";
                this.dataForm.comunity = "out";
            }
            $vm.dataForm.zipCodeCliente = null;
        },
        dataOk(){
            let $vm = this;
            $vm.$emit('dataReady', $vm.dataForm)
        },
        validarCorreoConfirmacion(){
            if( this.pasteDetected ){
                this.dataForm.emailConfirmation = null;
            }
            this.pasteDetected = false;
        },
        quitarCupon(){
            this.$store.commit("removeCouponToCartItem", {itemCouponCode: this.couponCode })
            this.couponCode = "";
            this.dataCoupon = null;
            this.applyingCupon = false;
        }

    },
    computed: {
        codigoYaAplicado(){
            return this.$store.getters.cuponExistente;
        },
        readOnlyCoupon(){
            if( this.$store.getters.cuponExistente === '' ){
                return false;
            }else{
                return true;
            }
        },
        descuentoAplicable(){
            return this.$store.getters.descuentoPorPromociones;
        }
    },
    async mounted(){
        const listaDePaises = Object.values(data);
        this.paises = listaDePaises.map( paisData => {
            let select = false;
            if(paisData.iso === "ES"){
                select = true
            }
            let obj = { 
                value: paisData.iso,
                text: paisData.name,
                selected: select
            }
            return obj;
        } )
        this.couponCode = this.$store.getters.cuponExistente ?? "";
        this.provincias = this.$store.getters.provincias;
    }
}

</script>
