var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c(
        "div",
        {
          staticClass: "loader-component",
          class: { "d-none": !_vm.movingToCart },
          attrs: { id: "loader" },
        },
        [
          _c("img", {
            staticClass: "animated flip infinite",
            staticStyle: { height: "220px", width: "auto" },
            attrs: {
              src: require("../assets/SimboloCastilloRojoCorporativa.svg"),
            },
          }),
        ]
      ),
      _c("div", { staticClass: "container-fluid px-md-5 px-2 mt-4 mb-4" }, [
        _c("div", { staticClass: "d-flex pe-0 w-100" }, [
          _c(
            "div",
            {
              staticClass: "text-start pt-3 pe-3",
              staticStyle: { width: "100%" },
            },
            [
              _c(
                "h4",
                {
                  staticClass: "my-auto pb-0 mb-0 spainhn-title",
                  attrs: { role: "button" },
                },
                [
                  _c("strong", [
                    _vm._v(" " + _vm._s(_vm.siteData.monumentName)),
                  ]),
                ]
              ),
              _c("h5", { staticClass: "mb-0" }, [
                _c("strong", { staticClass: "spainhn-subtitle" }, [
                  _vm._v(_vm._s(_vm.siteData.webName)),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "container-fluid px-md-5 px-0 mt-0 px-0 mb-4",
          class: { "d-none": _vm.visitaFirstDate === null },
        },
        [
          _c(
            "div",
            {
              staticClass: "container-fluid col-md-8 col-12 mt-0 mx-auto",
              attrs: { id: "horarios-disponibles" },
            },
            [
              _c(
                "b-card",
                {
                  staticClass: "d-flex pe-0 w-100 container px-0 px-md-0",
                  staticStyle: { "background-color": "#D9D9D9" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex my-auto pb-3 text-center align-items-center",
                    },
                    [
                      _c("div", { staticClass: "text-start center" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "20",
                              height: "23",
                              viewBox: "0 0 20 23",
                              fill: "none",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M13.0635 3.39704V1.26267M13.0635 3.39704V5.53141M13.0635 3.39704H8.35138M0.497803 9.80015V19.4048C0.497803 20.5836 1.43545 21.5392 2.59209 21.5392H17.2521C18.4088 21.5392 19.3464 20.5836 19.3464 19.4048V9.80015H0.497803Z",
                                stroke: "#850B0B",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M0.497803 9.80015V5.53141C0.497803 4.35263 1.43545 3.39704 2.59209 3.39704H4.68638",
                                stroke: "#850B0B",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M4.68628 1.26267V5.53141",
                                stroke: "#850B0B",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M19.3464 9.80015V5.53141C19.3464 4.35263 18.4088 3.39704 17.2521 3.39704H16.7285",
                                stroke: "#850B0B",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex",
                          class: { "d-none": _vm.selectedDate },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "my-auto ms-3 fw-bold spainhn-sections-title",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("default.RESERVATION_STEP_1"))
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex ms-auto",
                          class: { "d-none": _vm.selectedDate == null },
                        },
                        [
                          _c("div", { staticClass: "d-flex my-auto" }, [
                            _c("div", { staticClass: "ms-auto d-flex" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "my-auto fw-bold spainhn-sections-title",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedDate
                                        ? _vm.selectedDate.toLocaleDateString(
                                            "es-ES",
                                            { timeZone: "UTC" }
                                          )
                                        : null
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "ms-3" },
                              [
                                _c("font-awesome-icon", {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle.calendar",
                                      modifiers: { calendar: true },
                                    },
                                  ],
                                  staticClass:
                                    "circle-icon shadow spainhn-sections-title",
                                  attrs: { role: "button", icon: "fa-pen" },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-collapse",
                    {
                      staticClass: "border-top mt-2",
                      staticStyle: {
                        "border-color": "rgb(165, 61, 57) !important",
                        "border-width": "2px !important",
                      },
                      attrs: { id: "calendar" },
                      model: {
                        value: _vm.visibleDate,
                        callback: function ($$v) {
                          _vm.visibleDate = $$v
                        },
                        expression: "visibleDate",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-8 col-12 mx-auto mt-4 p-md-3" },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$t("default.CART_INSTRUCTIONS_1"))
                            ),
                          ]),
                          _c("vc-date-picker", {
                            ref: "calendario",
                            staticClass: "reservasV2-date-picker",
                            class: { "d-none": _vm.loading },
                            attrs: {
                              color: "red",
                              locale: _vm.selectedIdioma,
                              "disabled-dates": [{ start: null }],
                              timezone: "UTC",
                              "available-dates": _vm.enabledDates,
                              "is-expanded": "",
                              is24hr: "",
                              "min-date": new Date(),
                            },
                            on: {
                              "update:to-page": function ($event) {
                                return _vm.newMonth($event)
                              },
                              dayclick: function ($event) {
                                return _vm.daySelected($event)
                              },
                            },
                            model: {
                              value: _vm.selectedDate,
                              callback: function ($$v) {
                                _vm.selectedDate = $$v
                              },
                              expression: "selectedDate",
                            },
                          }),
                          _vm.loading
                            ? _c("div", { attrs: { id: "loader" } }, [
                                _c("img", {
                                  staticClass: "animated flip infinite",
                                  staticStyle: {
                                    height: "220px",
                                    width: "auto",
                                  },
                                  attrs: {
                                    src: require("../assets/SimboloCastilloRojoCorporativa.svg"),
                                  },
                                }),
                                _c("h5", [
                                  _vm._v(
                                    _vm._s(_vm.$t("default.BOOKINGS.LOADING"))
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "container flex flex-column px-0 my-2" },
                [
                  _c("div", { staticClass: "collapsable-header mx-auto" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "header-title-container header-active-typhograpy",
                      },
                      [
                        _c("span", { staticClass: "text-uppercase" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.horarioSelected === null
                                  ? _vm.$t("default.RESERVATION_STEP_2")
                                  : _vm.horarioParaResumen
                              ) +
                              " "
                          ),
                          !_vm.horarioSelected?.hora
                            ? _c(
                                "span",
                                {
                                  class: {
                                    "d-none": _vm.horarioSelected == null,
                                  },
                                  staticStyle: {
                                    width: "30px",
                                    height: "30px",
                                    "background-color": "inherit",
                                  },
                                  attrs: { role: "button" },
                                  on: {
                                    click: () => {
                                      _vm.scheduleModal = true
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "fa-circle-exclamation" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "p",
                            {
                              class: {
                                "d-none": !_vm.horarioSelected?.notaDeVenta,
                              },
                            },
                            [_vm._v(_vm._s(_vm.horarioSelected?.notaDeVenta))]
                          ),
                        ]),
                        _c(
                          "span",
                          {
                            class: { "d-none": _vm.horarioSelected == null },
                            staticStyle: {
                              width: "30px",
                              height: "30px",
                              "background-color": "inherit",
                            },
                            attrs: { role: "button" },
                            on: {
                              click: () => {
                                _vm.visibleHours = !_vm.visibleHours
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "fa-pen" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "animated fadeIn",
                        class: { "d-none": !_vm.visibleHours },
                        staticStyle: { width: "100%", "margin-top": "0" },
                      },
                      [
                        _c("hr", {
                          staticStyle: { color: "black", "margin-top": "0" },
                        }),
                        _c(
                          "div",
                          { staticStyle: { "text-align": "start !important" } },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-wrap" },
                              _vm._l(_vm.horarios, function (horario, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "d-flex py-2 my-2 mx-md-2 mx-auto",
                                    class: {
                                      "d-none":
                                        horario.hora == null ||
                                        _vm.loadingSchedule == true,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-start py-2 px-4",
                                        class: {
                                          "horario-selected":
                                            _vm.isThisHorarioSelected(
                                              horario.hora
                                            ),
                                        },
                                        staticStyle: {
                                          "background-color": "#fcf8e3",
                                          width: "100%",
                                        },
                                      },
                                      [
                                        _c(
                                          "mdb-btn",
                                          {
                                            staticClass:
                                              "spainhn-button my-auto",
                                            class: {
                                              "fa-bounce":
                                                _vm.horarioSelected == null,
                                            },
                                            attrs: {
                                              color: "indigo",
                                              size: "lg",
                                              disabled: horario.pausado == true,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setTarifa(horario)
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              class: {
                                                "fa-shake":
                                                  _vm.horarioSelected == null,
                                              },
                                              attrs: {
                                                icon: "fa-clock",
                                                size: "1x",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  new Date(
                                                    horario.hora
                                                  ).toLocaleTimeString(
                                                    "es-ES",
                                                    {
                                                      timeZone: "UTC",
                                                      hour: "2-digit",
                                                      minute: "2-digit",
                                                    }
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "small",
                                          {
                                            staticClass:
                                              "spainhn-text my-auto ms-2 w-100",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "d-none": !_vm.mostrarAforo,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "default.MENSAJE_AFORO"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "strong",
                                              {
                                                class: {
                                                  "d-none": !_vm.mostrarPlazas,
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$t(
                                                        "default.MENSAJE_AFORO2",
                                                        {
                                                          plazas:
                                                            _vm.getPlazasDisponibles(
                                                              horario.plazas,
                                                              horario.plazasOcupadas,
                                                              horario.plazasReservadas
                                                            ),
                                                        }
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "ms-3",
                                                class: {
                                                  "d-none":
                                                    !horario.notaDeVenta,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(horario.notaDeVenta)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              {
                                class: {
                                  "d-none":
                                    _vm.horarios.length > 0 ||
                                    _vm.loadingSchedule == true,
                                },
                              },
                              [
                                _c("p", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("default.RESERVATION_STEP_2_2")
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "text-start",
                                class: { "d-none": _vm.horariosLimitados.hide },
                              },
                              [
                                _c(
                                  "small",
                                  { staticClass: "spainhn-small-text" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("default.CART_STEP1_NOTES_1")
                                        )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " (" +
                                        _vm._s(
                                          _vm.$t("default.CART_STEP1_NOTES_2")
                                        ) +
                                        ") "
                                    ),
                                  ]
                                ),
                                _c("br"),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.horariosLimitados.note)),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "container flex flex-column px-0 my-2 align-items-center",
                },
                [
                  _c("div", { staticClass: "collapsable-header mx-auto" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "header-title-container header-active-typhograpy",
                      },
                      [
                        _c("span", { staticClass: "text-uppercase" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getTicketsAmount === 0
                                  ? _vm.$t("default.RESERVATION_STEP_3")
                                  : _vm.getTicketsAmount
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "span",
                          {
                            class: { "d-none": _vm.getTicketsAmount === 0 },
                            staticStyle: {
                              width: "30px",
                              height: "30px",
                              "background-color": "inherit",
                            },
                            attrs: { role: "button" },
                            on: {
                              click: () => {
                                _vm.ticketsVisible = !_vm.ticketsVisible
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "fa-pen" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "animated fadeIn",
                        class: { "d-none": !_vm.ticketsVisible },
                        staticStyle: { width: "100%", "margin-top": "0" },
                      },
                      [
                        _c("hr", {
                          staticStyle: { color: "black", "margin-top": "0" },
                        }),
                        _c(
                          "div",
                          { staticStyle: { "text-align": "start !important" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 spainhn-text pt-2 pb-2 px-2",
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("default.RESERVATION_STEP_3_1")
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm.tipoCobro
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-100 pe-3 text-wrap text-start spainhn-small-text",
                                    class: { "d-none": !_vm.tipoCobro },
                                  },
                                  [
                                    _c("small", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "default.MENSAJE_PRECIOS_INCLUYEN"
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.comision?.toLocaleString(
                                              "es-ES",
                                              { maximumFractionDigits: 2 }
                                            )
                                          ) +
                                          " " +
                                          _vm._s(_vm.simbolo) +
                                          "+IVA " +
                                          _vm._s(
                                            _vm.$t(
                                              "default.MENSAJE_EN_CONCEPTO"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                class: { "d-none": !_vm.precios.length },
                                attrs: { id: "precios-disponibles" },
                              },
                              [
                                _vm._l(_vm.precios, function (precio, index) {
                                  return _c(
                                    "mdb-container",
                                    {
                                      key: index,
                                      staticClass:
                                        "border-bottom py-2 border-top",
                                    },
                                    [
                                      _c(
                                        "mdb-row",
                                        {
                                          staticClass:
                                            "justify-content-between",
                                        },
                                        [
                                          _c(
                                            "mdb-col",
                                            { attrs: { col: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column mx-auto text-center",
                                                },
                                                [
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass:
                                                        "spainhn-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            precio.descripcion
                                                          ) +
                                                          " "
                                                      ),
                                                      precio.helpers?.length
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "mdb-tooltip",
                                                                {
                                                                  attrs: {
                                                                    material:
                                                                      "",
                                                                    trigger:
                                                                      "hover",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      attrs: {
                                                                        slot: "tip",
                                                                      },
                                                                      slot: "tip",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          precio.help
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        slot: "reference",
                                                                        icon: "question-circle",
                                                                      },
                                                                      slot: "reference",
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "spainhn-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .priceCalculator(
                                                              precio.precio
                                                            )
                                                            .toLocaleString(
                                                              "es-ES",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "EUR",
                                                              }
                                                            )
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "mdb-col",
                                            { attrs: { col: "12", md: "6" } },
                                            [
                                              _c("botones-reserva", {
                                                ref: "botoneras",
                                                refInFor: true,
                                                attrs: {
                                                  "min-personas":
                                                    _vm.selected[index]
                                                      .minPersonas,
                                                  tarifa: index,
                                                  "maximo-incremento": -1,
                                                },
                                                on: {
                                                  change: (evt) =>
                                                    _vm.preciosEdit(evt),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                _c(
                                  "div",
                                  { staticClass: "clearfix" },
                                  [
                                    _c(
                                      "mdb-btn",
                                      {
                                        staticClass:
                                          "spainhn-button mt-3 mb-2 ms-auto float-right",
                                        class: {
                                          "d-none": !_vm.precios.length,
                                        },
                                        attrs: {
                                          color: "indigo",
                                          size: "lg",
                                          disabled: _vm.productoAgregado,
                                        },
                                        on: { click: _vm.editarResumen },
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "default.SPAINHN_BUTTON_ADD_CART"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mt-2 border px-3 py-4 border-warning mb-3",
                                class: { "d-none": _vm.precios.length },
                                staticStyle: { "background-color": "#fcf8e3" },
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-2 col-12 my-auto" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass:
                                              "my-auto text-warning mx-auto",
                                            attrs: {
                                              icon: "fa-circle-exclamation",
                                              size: "3x",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-10 mx-auto" }, [
                                    _c("p", [
                                      _c(
                                        "strong",
                                        { staticClass: "text-spainhn my-auto" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "default.MENSAJE_SELECCIONAR_HORARIO"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm.seats
                ? _c("seats-configurator", {
                    attrs: {
                      expanded: _vm.visibleSeats,
                      zonas: _vm.zonasInfo,
                      personas: _vm.getTicketsAmount,
                      originLayout: _vm.originLayout,
                      imagenIlustrativa: _vm.imagenIlustrativa,
                    },
                    on: { getAsientos: (evt) => _vm.setAsientos(evt) },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "container flex flex-column px-0 my-2 align-items-center",
                },
                [
                  _c(
                    "div",
                    [
                      _vm.total
                        ? _c(
                            "span",
                            { staticClass: "spainhn-sections-title" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.total.toLocaleString("es-ES", {
                                      style: "currency",
                                      currency: "EUR",
                                    })
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-button",
                        {
                          staticClass: "finish-button mx-auto text-white",
                          attrs: {
                            disabled:
                              !_vm.habilitarPasoACarrito || _vm.movingToCart,
                          },
                          on: { click: _vm.goToCarrito },
                        },
                        [
                          _c("span", { staticClass: "finish-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("default.RESERVATION_STEP_5")) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "container-fluid px-5 mt-4 mb-4",
          class: { "d-none": _vm.visitaFirstDate !== null },
        },
        [
          _c("div", { staticClass: "row margen-estandard d-flex" }, [
            _c("div", { staticClass: "col-md-7 h-100 my-auto" }, [
              _c(
                "div",
                {
                  staticClass:
                    "text-start d-flex pt-3 flex-column h-100 mt-auto",
                  staticStyle: { width: "100%" },
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "my-3 pb-0 mb-0 spainhn-subtitle-black mx-auto mt-auto",
                      class: {
                        "d-none": _vm.loading && _vm.paquetes.length === 0,
                      },
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("default.SERVICES_BAR_ENTRADAS"))
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass:
                        "my-3 pb-0 mb-0 spainhn-title mx-auto spainhn-title-text3",
                      class: { "d-none": _vm.loading },
                      attrs: { role: "button" },
                    },
                    [
                      _c("strong", [
                        _vm._v(" " + _vm._s(_vm.siteData.monumentName)),
                      ]),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "mb-0 mx-auto",
                      class: { "d-none": _vm.loading },
                    },
                    [
                      _c("strong", { staticClass: "spainhn-subtitle" }, [
                        _vm._v(_vm._s(_vm.siteData.webName)),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "mx-auto" },
                    _vm._l(_vm.paquetes, function (paqueteM, index) {
                      return _c("recommendations-card", {
                        key: index,
                        attrs: {
                          title: paqueteM.name,
                          showDefault: false,
                          text: _vm.$t("default.CARDS.PLACE", {
                            msg: paqueteM.monumentoId.nombre,
                          }),
                          "price-description": paqueteM.precioOrientativo
                            ? _vm.$t("default.CARDS.START_FROM", {
                                msg: `${parseFloat(
                                  Number(paqueteM.precioOrientativo)
                                )?.toLocaleString("es-ES", {
                                  style: "currency",
                                  currency: "EUR",
                                })}`,
                              })
                            : "",
                          "image-url": `${_vm.imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`,
                          showBuyButton: true,
                        },
                        on: {
                          goTo: () =>
                            _vm.goToMicrositioPaqueteMonumento({
                              name: paqueteM.name,
                              id: paqueteM._id,
                            }),
                          buy: () => _vm.goToBuy(paqueteM, true),
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pt-0 d-flex my-1 mx-auto",
                      staticStyle: { "margin-left": "6rem", width: "200px" },
                    },
                    [
                      _c("img", {
                        staticClass: "img-fluid my-auto",
                        class: { "d-none": _vm.loading },
                        staticStyle: { height: "80px", width: "200px" },
                        attrs: { src: require("../assets/LOGO REPAHIS.png") },
                      }),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "mx-auto",
                      class: { "d-none": _vm.loading },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("default.MENSAJE_SUGERENCIA_ACTIVIDAD"))
                      ),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "mx-auto",
                      class: { "d-none": _vm.loading },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("default.MENSAJE_AYUDA_PLANIFICACION"))
                      ),
                    ]
                  ),
                  _c(
                    "mdb-btn",
                    {
                      staticClass: "spainhn-button mx-auto",
                      class: { "d-none": _vm.loading },
                      attrs: { color: "indigo", href: "#" },
                      on: { click: _vm.goToContact },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("default.MENSAJE_ORGANIZA_TU_RESERVA"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column pt-3 col-12 col-md-5 col-xxl-4",
                class: { "d-none": _vm.loading },
              },
              [
                _c(
                  "strong",
                  {
                    staticClass: "text-center mx-auto w-100 spainhn-subtitle-2",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("default.MICROSITES_TICKETS_RECOMMENDATIONS")
                      )
                    ),
                  ]
                ),
                _c("div", {
                  staticClass: "w-100 border-bottom",
                  staticStyle: {
                    height: "2vh",
                    "border-color":
                      "var(--spainhn-rojo-corporativo) !important",
                    "border-width": "medium",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap" },
                  _vm._l(_vm.eventosRecomendados, function (evento, index) {
                    return _c("cards-thumbnails-3", {
                      key: index,
                      class: { "d-none": index > 2 },
                      attrs: {
                        monument: evento.webName,
                        nombreUrl: evento.visitaId,
                        tipo: "Entrada",
                        text: evento.webDescription,
                        imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.imagen}`,
                      },
                      on: { goTo: _vm.goToEntrada },
                    })
                  }),
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "mdb-container",
        { staticClass: "mt-3", attrs: { fluid: "" } },
        [_c("ComponentsCloud", { staticClass: "w-100" })],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "col-md-4 col-md-offset-4 col-xs-8 col-xs-offset-2",
          attrs: {
            id: "mensajes",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
          },
          model: {
            value: _vm.reservaCancelada,
            callback: function ($$v) {
              _vm.reservaCancelada = $$v
            },
            expression: "reservaCancelada",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "col-xs-12",
              staticStyle: { "text-align": "right" },
            },
            [
              _c(
                "button",
                {
                  attrs: { type: "button" },
                  on: { click: () => (_vm.reservaCancelada = false) },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/close.svg"),
                      width: "14px",
                      height: "14px",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c("p", {
            staticClass: "alert sys-message",
            attrs: { role: "alert" },
          }),
          _c("div", { staticClass: "col-xs-12 justify-content-center" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c("img", {
                staticClass: "mx-auto",
                attrs: {
                  src: require("@/assets/cancel-face.svg"),
                  width: "50px",
                  height: "50px",
                },
              }),
            ]),
            _c("div", { staticClass: "d-flex" }, [
              _c("p", { staticClass: "mx-auto spainhn-text" }, [
                _vm._v(_vm._s(_vm.$t("default.BOOKINGS.BOOKING_CANCEL"))),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "mdb-btn",
                  {
                    staticClass: "mx-auto spainhn-button",
                    attrs: { color: "indigo" },
                    on: { click: () => (_vm.reservaCancelada = false) },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.BUTTONS.ACCEPT")))]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("ScheduleMonumentModal", {
        attrs: {
          danger: "",
          title: _vm.$t("default.MICROSITES_SCHEDULE"),
          html: "",
          description: _vm.horario,
          popupStart: _vm.scheduleModal,
        },
        on: { close: () => (_vm.scheduleModal = false) },
      }),
      _c("WelcomeModal", {
        attrs: {
          popupStart: _vm.mostrarMensajeDeWelcome,
          title: _vm.welcomeMessageTitle,
          subtitle: _vm.welcomeMessageSubtitle,
        },
        on: { close: () => (_vm.mostrarMensajeDeWelcome = false) },
      }),
      _c("error-alert", {
        attrs: {
          show: _vm.asientosOcupadosAlert,
          mensaje: _vm.$t("default.SORRY_SEATS_MESSAGE"),
        },
        on: { close: () => _vm.refrescarAsientos() },
      }),
      _c("success-alert", {
        attrs: {
          show: _vm.itemAgregado,
          mensaje: _vm.$t("default.BOOKINGS.BOOKING_TO_CART"),
        },
        on: { close: _vm.cerrarConfirmacion },
      }),
      _c("error-alert", {
        attrs: {
          show: _vm.itemEliminado,
          mensaje: _vm.$t("default.BOOKINGS.BOOKING_REMOVED"),
        },
        on: { close: () => (_vm.itemEliminado = false) },
      }),
      _c("error-alert", {
        attrs: {
          cancel: true,
          carrito: true,
          show: _vm.itemDuplicadoAlert,
          mensaje: _vm.$t("default.BOOKINGS.BOOKING_AUTO_CANCEL"),
        },
        on: { close: _vm.duplicarItem, cancelar: _vm.cancelarOperacion },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }