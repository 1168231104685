var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LayoutDefault px-0 pt-0", staticStyle: { width: "100%" } },
    [
      _c(
        "b-navbar",
        {
          staticClass: "pt-0 pb-0 spainhNavbar",
          attrs: { toggleable: "lg", sticky: true },
        },
        [
          _c(
            "b-navbar-brand",
            { staticClass: "d-flex pb-4", attrs: { href: "/" } },
            [
              _c(
                "div",
                {
                  staticClass: "pt-0 d-flex",
                  staticStyle: { "margin-left": "6rem" },
                },
                [
                  _c("img", {
                    staticClass: "img-fluid my-auto",
                    staticStyle: { height: "80px", width: "200px" },
                    attrs: { src: require("../assets/LOGO REPAHIS.png") },
                  }),
                ]
              ),
            ]
          ),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                {
                  staticClass: "pe-5 ms-auto mt-auto mb-lg-0 mb-md-0 false",
                  attrs: { align: "center", small: true },
                },
                [
                  _c(
                    "b-nav-item",
                    {
                      staticClass:
                        "spainhn-main-menu-text text-monospace mt-auto border-start h-100",
                      attrs: { href: "/" },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mt-auto pt-3",
                          staticStyle: { color: "rgb(77,77,77) !important" },
                        },
                        [_vm._v(_vm._s(_vm.$t("default.MAIN_MENU_INICIO")))]
                      ),
                    ]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      staticClass:
                        "spainhn-main-menu-text text-monospace mt-auto border-start",
                      attrs: { href: "/castillos" },
                    },
                    [
                      _c(
                        "p",
                        { staticStyle: { color: "rgb(77,77,77) !important" } },
                        [_vm._v(_vm._s(_vm.$t("default.MAIN_MENU_MONUMENTOS")))]
                      ),
                    ]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      staticClass:
                        "spainhn-main-menu-text text-monospace mt-auto border-start h-100",
                      attrs: { href: "/rutas-recomendadas" },
                    },
                    [
                      _c(
                        "p",
                        { staticStyle: { color: "rgb(77,77,77) !important" } },
                        [_vm._v(_vm._s(_vm.$t("default.MAIN_MENU_RUTAS")))]
                      ),
                    ]
                  ),
                  _c(
                    "b-nav-item",
                    {
                      staticClass:
                        "spainhn-main-menu-text mt-auto border-start",
                      attrs: { href: "/blog" },
                    },
                    [
                      _c(
                        "p",
                        { staticStyle: { color: "rgb(77,77,77) !important" } },
                        [_vm._v(_vm._s(_vm.$t("default.MAIN_MENU_NOTICIAS")))]
                      ),
                    ]
                  ),
                  _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass:
                        "spainhn-main-menu-text my-auto border-start",
                      attrs: {
                        id: "servicios-dropdown",
                        right: "",
                        dropright: "",
                        text: _vm.$t("default.MAIN_MENU_SERVICIOS"),
                      },
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "spainhn-main-menu-text",
                          attrs: { href: "/castillos/servicios/visitas" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("default.MAIN_MENU_SERVICES_VISITS"))
                          ),
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "spainhn-main-menu-text",
                          attrs: { href: "/servicios/alojamiento" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("default.MAIN_MENU_SERVICES_ALOJAMIENTO")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "spainhn-main-menu-text",
                          attrs: { href: "/servicios/restaurantes" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("default.MAIN_MENU_SERVICES_RESTAURANTES")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "spainhn-main-menu-text",
                          attrs: {
                            href: "/castillos/servicios/eventos-singulares",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("default.MAIN_MENU_SERVICES_EVENTOS"))
                          ),
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "spainhn-main-menu-text",
                          attrs: {
                            href: "/castillos/servicios/experiencias-exclusivas",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("default.MAIN_MENU_SERVICES_EXPERIENCIAS")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "spainhn-main-menu-text",
                          attrs: { href: "/servicios/bodas" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("default.MAIN_MENU_SERVICES_BODA"))
                          ),
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "spainhn-main-menu-text",
                          attrs: { href: "/servicios/espacios" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("default.MAIN_MENU_SERVICES_ESPACIOS")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "spainhn-main-menu-text",
                          attrs: { href: "/servicios/exposiciones" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("default.MAIN_MENU_SERVICES_MUSEOS"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    {
                      staticClass:
                        "spainhn-main-menu-text mt-auto border-start border-end me-2 d-flex w-100",
                      attrs: { href: "/clientes/login" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "mt-1 me-1",
                            attrs: { icon: "fa-circle-user" },
                          }),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                color: "rgb(77,77,77) !important",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("default.MAIN_MENU_ACCEDER"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "d-flex my-auto h-100" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex my-auto" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "my-auto",
                          class: { "fa-bounce": _vm.nuevoItemAgregado },
                          attrs: { icon: "fa-cart-shopping" },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "spainhn-main-menu-text my-auto",
                      attrs: {
                        right: "",
                        "no-caret": "",
                        text: _vm.ticketsAmount,
                        href: "/carrito",
                      },
                    },
                    [
                      _vm._l(_vm.itemsInCart, function (item) {
                        return _c(
                          "b-dropdown-item",
                          { key: item.uuid },
                          [
                            _c("entrada-resumen", {
                              staticClass: "me-auto",
                              attrs: { item: item },
                            }),
                          ],
                          1
                        )
                      }),
                      _c("b-dropdown-divider"),
                      _c("b-dropdown-item", [
                        _c("strong", [
                          _vm._v(
                            "Importe Total " +
                              _vm._s(_vm.totalAPagar.toLocaleString()) +
                              " €"
                          ),
                        ]),
                      ]),
                      _c(
                        "b-dropdown-item",
                        { staticClass: "mx-auto", attrs: { href: "/carrito" } },
                        [
                          _c("strong", { staticClass: "mx-auto" }, [
                            _vm._v("Ver carrito"),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex",
                      attrs: { role: "button" },
                      on: { click: _vm.changeLanguage },
                    },
                    [
                      _c("country-flag", {
                        staticClass: "my-auto",
                        staticStyle: { "border-radius": "40% 40% 50% 50%" },
                        attrs: { country: _vm.idioma, size: "normal" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "main",
        { staticClass: "LayoutDefault__main bg-light container-fluid px-0" },
        [_vm._t("default")],
        2
      ),
      _c(
        "div",
        {
          staticClass: "d-flex w-100 newsletter",
          staticStyle: { "min-height": "30rem" },
          style: { backgroundImage: _vm.newsletterImage },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex mx-auto my-auto",
              staticStyle: {
                "min-height": "18em",
                width: "28vw",
                "min-width": "300px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "mx-auto bg-white px-3 pt-3 pb-3",
                  staticStyle: { "border-radius": "10px" },
                },
                [
                  _c(
                    "b-form",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "h5",
                        { staticClass: "mx-auto spainhn-subtitle-text" },
                        [
                          _c("small", [
                            _vm._v("Suscríbete a nuestro newsletter"),
                          ]),
                        ]
                      ),
                      _c("p", { staticClass: "spainhn-small-text" }, [
                        _c("strong", [
                          _vm._v(
                            "Recibe novedades y descuentos de Spain Heritage Network y comienza tu aventura"
                          ),
                        ]),
                      ]),
                      _c(
                        "b-form-group",
                        { staticClass: "my-0" },
                        [
                          _c("p", { staticClass: "spainhn-text my-0" }, [
                            _c("strong", [_vm._v("INTRODUCE TU EMAIL")]),
                          ]),
                          _c("b-form-input", {
                            staticClass: "w-50 mx-auto",
                            staticStyle: { "border-radius": "10px" },
                            attrs: {
                              type: "email",
                              size: "sm",
                              placeholder: "hola@ejemplo.com",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-checkbox",
                        { staticClass: "text-start d-flex my-3" },
                        [
                          _c("small", [
                            _vm._v("Acepto los "),
                            _c(
                              "a",
                              {
                                staticClass: "link-dark",
                                attrs: { href: "/condiciones-generales" },
                              },
                              [_vm._v("términos y condiciones de privacidad")]
                            ),
                            _vm._v(" de Spain Heritage Network"),
                          ]),
                        ]
                      ),
                      _c(
                        "mdb-btn",
                        {
                          staticClass: "spainhn-button",
                          attrs: { color: "indigo" },
                        },
                        [_vm._v("¡Suscribirme!")]
                      ),
                      _c(
                        "a",
                        {
                          staticClass:
                            "spainhn-small-text mt-3 link-dark d-block",
                          attrs: { href: "/privacidad-cookies" },
                        },
                        [
                          _c("small", [
                            _c("strong", [
                              _vm._v(
                                "Acerca del tratamiento de datos de Spain Heritage Network"
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("footer", { staticClass: "LayoutDefault_footer bg-dark text-white" }, [
        _c(
          "div",
          { staticClass: "row w-100 pt-4 pb-4" },
          [
            _c(
              "b-col",
              {
                staticClass: "ps-4 mb-4 mb-md-0",
                attrs: { cols: "12", md: "4" },
              },
              [
                _c("img", {
                  staticClass: "img-fluid w-50",
                  attrs: {
                    src: require("@/assets/LOGO REPAHIS (BLANCO SIN FONDO).png"),
                  },
                }),
              ]
            ),
            _c("b-col", { attrs: { cols: "5", md: "2" } }, [
              _c("div", { staticClass: "d-flex align-items-start" }, [
                _c(
                  "small",
                  {
                    staticClass: "fw-bold",
                    staticStyle: { "font-size": "small" },
                  },
                  [_vm._v("Spain Heritage Network")]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column mt-3 text-start spainhn-text",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "link-light",
                      attrs: { href: "/quienes-somos" },
                    },
                    [
                      _c("small", [
                        _vm._v(_vm._s(_vm.$t("default.FOOTER_WHO"))),
                      ]),
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "link-light",
                      attrs: { href: "/aviso-legal" },
                    },
                    [
                      _c("small", [
                        _vm._v(_vm._s(_vm.$t("default.FOOTER_LEGAL"))),
                      ]),
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "link-light",
                      attrs: { href: "/privacidad-cookies" },
                    },
                    [
                      _c("small", [
                        _vm._v(
                          _vm._s(_vm.$t("default.FOOTER_PRIVACY_AND_COOKIES"))
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "link-light",
                      attrs: { href: "/condiciones-generales" },
                    },
                    [
                      _c("small", [
                        _vm._v(_vm._s(_vm.$t("default.FOOTER_SELL_GENERALS"))),
                      ]),
                    ]
                  ),
                  _c(
                    "a",
                    { staticClass: "link-light", attrs: { href: "/contacto" } },
                    [
                      _c("small", [
                        _vm._v(_vm._s(_vm.$t("default.FOOTER_CONTACT"))),
                      ]),
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "link-light",
                      attrs: { href: "https://admin.spainheritagenetwork.com" },
                    },
                    [
                      _c("small", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("default.FOOTER_ACCESS")) + " "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _c(
              "b-col",
              { staticClass: "ps-0 ps-md-4", attrs: { cols: "5", md: "3" } },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-start text-start" },
                  [
                    _c(
                      "small",
                      {
                        staticClass: "fw-bold",
                        staticStyle: { "font-size": "small" },
                      },
                      [_vm._v(_vm._s(_vm.$t("default.FOOTER_CONTACT_TITLE")))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column align-items-start text-start mt-3 text-start spainhn-text",
                  },
                  [
                    _c("small", [
                      _vm._v("Email: info@spainheritagenetwork.com"),
                    ]),
                    _c("div", { staticClass: "d-flex mt-3 text-start" }, [
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: {
                            href: "https://www.facebook.com/castillosypalaciosdeespana",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "me-2",
                            attrs: {
                              icon: "fa-brands fa-facebook-square",
                              size: "2x",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: { href: "https://twitter.com/redcastillos" },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "me-2",
                            attrs: {
                              icon: "fa-brands fa-twitter-square",
                              size: "2x",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: {
                            href: "https://www.instagram.com/redcastillosypalacios/",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "me-2",
                            attrs: {
                              icon: "fa-brands fa-instagram-square",
                              size: "2x",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link-light",
                          attrs: {
                            href: "https://www.linkedin.com/company/asociaci%C3%B3n-y-club-de-producto-castillos-y-palacios-tur%C3%ADsticos-de-espa%C3%B1a/",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "me-2",
                            attrs: {
                              icon: "fa-brands fa-linkedin",
                              size: "2x",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }